<template>
  <div class="layout">
<!--    <img class="layout_img_logo" src="../../image/logo.png" alt="" >-->
    <div class="layout_content">
      <p class="layout_content_title">{{ pageTitle }}</p>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "layout",
  computed:{
    ...mapState({
      pageTitle:state => state.register.pageTitle,
      staff:state => state.register.staff
    }),
    // pageTitle() {
    //   return this.$store.getters["register/getPageTitle"]
    // }
  },
  mounted() {
      this.loadProfile().then(profile=>{
          this.staff.email = profile.data.staff.email;
          this.staff.userId = profile.data.staff.id;
      }).catch((e)=>{
          console.log('失败',e.response)
          this.$router.push({name:'login'})
      })

  },
    methods:{
      ...mapActions({
          loadProfile:'passport/loadProfile'
      })
    }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../../styles/mixins.styl'
.layout
  width 100%
  height 1024px
  background no-repeat url("../../image/layout_page_bac.jpg")
  background-size 750px
  background-position left top
  background-repeat no-repeat
  position relative
  .layout_img_logo
    width 74px
    height 102px
    float left
    padding  31px 36px
  .layout_content
    width 750px
    height 938px
    //height auto
    background #FFFFFF
    //box-shadow 0 0 40px 0 #C08560
    border-radius 60px 60px 0 0
    position absolute
    top 700px
    .layout_content_title
      height 47px
      font-size 48px
      fontFamily()
      font-weight bold
      color $theme
      line-height 48px
      text-align center
      margin 40px 0 30px
</style>
